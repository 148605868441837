<template>
  <div>

    <h1>Analytics</h1>
  </div>

</template>

<script>

  export default {
    name: 'Home',


  }
</script>

<template>
  <v-snackbars :objects.sync="notifications" top right>
    <template v-slot:action="{ close, index, message, id }">
      <v-btn
          icon
          color="white"
          text
          @click="close()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbars>
</template>

<script>
import {mapGetters} from "vuex";
import VSnackbars from "v-snackbars"

export default {
  name: "Notification",
  computed: mapGetters(["notifications"]),
  components:{
    "v-snackbars": VSnackbars
  },
  methods: {
  }
}
</script>

<style scoped>

</style>

<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Login Form</v-toolbar-title>
          </v-toolbar>
          <v-form @submit.prevent="login">
            <v-card-text>
              <v-text-field
                prepend-icon="person"
                name="login"
                label="Username"
                type="text"
                v-model="username"
              ></v-text-field>
              <v-text-field
                id="password"
                prepend-icon="lock"
                name="password"
                label="Password"
                type="password"
                v-model="password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn href="/resetpw">Reset Password</v-btn>
              <v-btn type="submit" color="primary">Login</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar
        v-model="snackbar.display"
        :color="snackbar.color"
        timeout="2000"
        right
        top
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import router from "../router";

export default {
  data() {
    return {
      username: '',
      password: '',
      snackbar: {
        color: '',
        display: false,
        text: ''
      }
    }
  },
  methods: {
    validateDetails() {
      if (this.username.trim() === "") {
        this.snackbar = {
          color: 'error',
          display: true,
          text: 'Kindly add username first'
        }
        return false
      }
      if (this.password.trim() === "") {
        this.snackbar = {
          color: 'error',
          display: true,
          text: 'Kindly add password first'
        }
        return false
      }
      return true
    },
    login() {
      if (this.validateDetails()) {
        this.$http.post('/rest-auth/login/', {
          username: this.username,
          password: this.password,
        }).then(response => {
          if (response.status === 200) {
            console.log(response)
            window.localStorage.setItem('token', response.data.key)
            this.$http.get('/user-detail',{
              headers :{
                'Authorization': `token ${response.data.key}`
              }
            }).then(res => {
              if(res.status === 200) {
                this.$http.get('is-super-user/'+res.data.username+'/').then(resp => {
                  if(resp.status === 200) {
                    let userData = res.data
                    userData.isSuperUser = resp.data
                    window.localStorage.setItem('user', JSON.stringify(userData))
                    this.snackbar = {
                      color: 'success',
                      display: true,
                      text: 'User Login successful'
                    }
                    if (userData && userData.technology_partner !== null) {
                      location.replace('tech-analytics')
                    } else {
                      location.replace('/checks')
                    }
                  }
                })
              }
            })
          }
        }).catch(err => {
          if (err.response && err.response.status === 400) {
            this.snackbar = {
              color: 'error',
              display: true,
              text: 'Unable to login with given credentials'
            }
          } else {
            this.snackbar = {
              color: 'error',
              display: true,
              text: err
            }
          }
        })
      }
    }
  }
}
</script>

<template>
  <v-app>

    <router-view>
    </router-view>

  </v-app>
</template>

<script>

export default {
  name: "App",

  data: () => ({
    //
  }),
  mounted() {
    if (this.$route.name !== "Login") {
      this.$http.get('/rest-auth/user/').then(resp => {
        if (resp.status !== 200) {
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          this.$router.push('/login')
        }
      })
    }
  }
};
</script>

import Vue from 'vue'
import Vuex from 'vuex'

import notification from "./modules/notification/notification";
import sessionNotification from "./modules/sessionNotification/sessionNotification";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    clientLogo: null
  },
  mutations: {
    setClientLogo(currentStateObject, logo){
      currentStateObject.clientLogo = logo
    },
  },
  actions: {
  },
  modules: {
    notification,
    sessionNotification,
  }
})

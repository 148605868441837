<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Reset Password</v-toolbar-title>
          </v-toolbar>
          <v-form @submit.prevent="sendResetEmail">
            <v-card-text>
              <v-text-field
                prepend-icon="person"
                name="login"
                label="Email"
                type="text"
                v-model="username"
                :readonly="emailSent"
                :disabled="sendingMail"
              ></v-text-field>
              <!-- <v-text-field
                id="password"
                prepend-icon="lock"
                name="password"
                label="Password"
                type="password"
                v-model="password"
              ></v-text-field> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn type="submit" v-if="!emailSent">Send Reset Link</v-btn>
              <span v-if="emailSent">Visit the link sent to your email</span>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackbar.display"
      :color="snackbar.color"
      timeout="2000"
      right
      top
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      // password: "",
      snackbar: {
        color: "",
        display: false,
        text: "",
      },
      emailSent: false,
      sendingMail: false
    };
  },
  methods: {
    validateDetails() {
      if (this.username.trim() === "") {
        this.snackbar = {
          color: "error",
          display: true,
          text: "Kindly add username first",
        };
        return false;
      }
      return true;
    },
    sendResetEmail() {
      if (this.validateDetails()) {
        this.sendingMail = true
        this.$http
          .post("/rest-auth/password/reset/", {
            username: this.username,
            email: this.username
          })
          .then((response) => {
            if (response.status === 200) {
              this.snackbar = {
                color: "success",
                display: true,
                text: "Reset email has been sent",
              };
              this.emailSent = true;
              this.sendingMail = false;
            } else {
              this.snackbar = {
                color: "error",
                display: true,
                text: "Unable to login with given credentials",
              };
            }
          })
          .catch((err) => {
            this.snackbar = {
              color: "error",
              display: true,
              text: "Unable to login with given credentials",
            };
          });
      }
    },
  },
};
</script>
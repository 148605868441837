<template>
  <div class="about">

    <v-navigation-drawer :permanent="$vuetify.breakpoint.mdAndUp" app v-model="drawer">
      <v-list>
        <v-list-item>
          <v-img v-if="$store.state.clientLogo" width="224" height="31.23" :src="$store.state.clientLogo" contain />
          <v-img v-else width="224" height="31.23" src="@/assets/logo.png" contain />
        </v-list-item>

        <!--        <v-list-item link>-->
        <!--          <v-list-item-avatar>-->
        <!--            <v-img src="https://randomuser.me/api/portraits/women/85.jpg" contain></v-img>-->
        <!--          </v-list-item-avatar>-->
        <!--          <v-list-item-content>-->

        <!--            <v-list-item-title class="text-h6">-->
        <!--              Sandra Adams-->
        <!--            </v-list-item-title>-->
        <!--            <v-list-item-subtitle>sandra_a88@gmail.com</v-list-item-subtitle>-->
        <!--          </v-list-item-content>-->
        <!--          <v-list-item-action>-->
        <!--            <v-icon>mdi-menu-down</v-icon>-->
        <!--          </v-list-item-action>-->
        <!--        </v-list-item>-->
      </v-list>

      <v-divider></v-divider>

      <v-list nav>
        <v-list-item link :to="{name:'Checks'}" v-if="user && user.technology_partner === null">
          <v-list-item-icon>
            <v-icon>checklist</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Verifications</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user && user.verification_officer !== null" link :to="{name:'PendingChecks'}">
          <v-list-item-icon>
            <v-icon>checklist</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Pending Verifications</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{name: user && user.technology_partner? 'TechAnalytics': 'Analytics'}">
          <v-list-item-icon>
            <v-icon>insights</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Analytics</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{name:'Agents'}" v-if="user && user.client !== null && hasAgentConfig">
          <v-list-item-icon>
            <v-icon>manage_accounts</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Agents Management</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{name:'VerificationOfficer'}" v-if="user && (user.isSuperUser === 'True' || user.client !== null)">
          <v-list-item-icon>
            <v-icon>manage_accounts</v-icon>
          </v-list-item-icon>
          <v-list-item-title>VO Management</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{name:'UpdateProfile'}" v-if="user && user.client !== null">
          <v-list-item-icon>
            <v-icon>manage_accounts</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Update Profile</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{name:'Clients'}" v-if="user && user.isSuperUser === 'True'">
          <v-list-item-icon>
            <v-icon>manage_accounts</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Client Management</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list v-if="$store.state.clientLogo" style="position: absolute; bottom: 0">
        <p class="text-center">Powered By:</p>
        <v-list-item>
          <v-img width="224" height="52.23px" src="@/assets/logo.png" contain />
        </v-list-item>
      </v-list>
      <v-img>

      </v-img>
    </v-navigation-drawer>

    <v-app-bar app color="primary">
      <v-app-bar-nav-icon v-if="!$vuetify.breakpoint.mdAndUp" color="white" @click.stop="drawer = !drawer" />
      <v-spacer></v-spacer>
      <v-menu
          transition="slide-y-transition"
          v-if="pendingSessionIds.length > 0"
          bottom
          :content-class="pendingSessionIds.length > 0? 'my-menu': 'my-menu-hide'"
      >
        <template v-slot:activator="{ on }">
          <v-badge
              :value="pendingSessionIds.length > 0"
              overlap
              color="red"
              class="mr-4"
              v-on="on"
          >
            <template v-slot:badge>
              <span>{{ pendingSessionIds.length }}</span>
            </template>
            <v-icon v-on="on" color="#fff">mdi-bell</v-icon>
          </v-badge>
        </template>
        <v-list v-if="pendingSessionIds.length > 0" class="pending">
          <v-list-item
              v-for="id in pendingSessionIds"
              :key="id"
              @click="openLink(id)"
          >
            <v-list-item-title>Session {{ id }} needs manual verification</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu v-if="this.user" :close-on-content-click="false" :nudge-width="200" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn  v-bind="attrs" v-on="on" class="mr-1 user-btn" elevation="0">
            <v-item class="mr-1">{{ user.username }}</v-item>
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-card v-if="this.user">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ user.username }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon class="text--primary" @click.stop="password_dialog = true">
                  <v-icon>mdi-lock</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn icon class="text--primary" @click="logout">
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <Notification />
        <!-- If using vue-router -->
        <router-view />
        <v-row justify="center">
          <v-dialog
              v-model="password_dialog"
              persistent
              max-width="600px"
          >
            <v-card>
              <v-form @submit.prevent="changePassword" ref="change_password_form">
                <v-card-title>
                  <span class="text-h5">Change Password</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                      <v-row>
                      <v-col cols="12">
                        <v-text-field
                            label="New Password*"
                            type="password"
                            required
                            :rules="new_password_rules"
                            v-model="new_password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                            label="Confirm New Password*"
                            type="password"
                            required
                            :rules="confirm_new_password_rules"
                            v-model="confirm_new_password"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    type="button"
                    @click="closeDialog"
                >
                  Close
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    type="submit"
                >
                  Save
                </v-btn>
              </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import Notification from './Notification.vue';

export default {
  computed: {
    ...mapGetters(['allSessions']),
    pendingSessionIds() {
      return this.allSessions
    }
  },
  components: {
    Notification,
  },
  data() {
    return {
      user: null,
      socket: null,
      drawer: false,
      password_dialog: false,
      hasAgentConfig: false,
      new_password:'',
      confirm_new_password:'',
      clientLogo: null,

      new_password_rules:[
        v => !!v || 'New Password is required',
        v => (v && v.length >= 8) || 'New Password must be greater than 8 characters',
      ],
      confirm_new_password_rules:[
          v => !!v || 'Confirm New Password is required',
          v => v===this.new_password || "Confirm New Password should match New Password",
      ]
    }
  },
  mounted() {
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'))
      if (this.user.client) {
        if (this.user.client.config && this.user.client.config.configuration) {
          this.user.client.config.configuration.forEach(e => {
            if (e.type === "agent_verification") {
              this.hasAgentConfig = true
            }
          })
        }
        if (this.user.client && this.user.client.logo) {
          this.$store.commit("setClientLogo", this.user.client.logo)
        }
      }
    }
    let token = localStorage.getItem('token')

    if (token && this.user && this.user.verification_officer !== null) {
      this.$store.commit('setLoading', true)
      this.getPendingSessions()
      const baseUrl = process.env.VUE_APP_BASE_URL.replace('http', 'ws')
      this.socket = new WebSocket(`${baseUrl}/ws/checks/user_${this.user.id}/?token=${token}`)

      this.socket.onmessage = (msg => {
        console.log("message", msg.data)
        const data = JSON.parse(msg.data)
        const session = data.message
        if (data.type === "logout") {
          if (this.socket !== null) {
            this.socket.close(3001, "User logged out")
          }
        } else {
          const inPending = this.pendingSessionIds.includes(session.id)
          if (session.status === 'Pending') {
            this.getPendingSessions()
            if (!inPending) {
              this.$store.dispatch("setNotification", {
                message: `Session id ${session.id} needs manual verification`,
                color: "success"
              })
            }
          } else if (inPending) {
            this.$store.commit("deleteSession", session.id)
          }
        }
      })
      this.socket.onclose = (msg => {
        console.log("msg", msg)
      })
      this.socket.onerror = function(err){
        console.log("error", err)
      }
    }
  },
  methods: {
    ...mapActions(["setNotification"]),
    openLink(id) {
      if (location.pathname !== `/checks/${id}`) {
        const current_check_id = location.pathname.split("checks/").pop()
        this.$router.push(`/checks/${id}`)
        console.log(current_check_id)
        if (current_check_id !== "/checks") {
          location.reload()
        }
      }
    },
    closeDialog(){
      this.password_dialog = false
      this.old_password = ''
      this.new_password = ''
      this.confirm_new_password = ''
      this.$refs.change_password_form.reset()
    },
    getPendingSessions() {
      this.$http.get('pending-sessions/').then(res => {
        this.$store.commit('setAllSessions', res.data.pending_session_ids)
        this.$store.commit('setLoading', false)
      })
    },
    changePassword(){
      if (this.$refs.change_password_form.validate()=== true){
        this.$http.post("/rest-auth/password/change/",{
          new_password1:this.new_password,
          new_password2:this.confirm_new_password
        }).then(resp=>{
          this.setNotification({
            message: "Password successfully updated",
            color: 'success'
          })
          this.password_dialog = false
        }).catch(err=>{
          if (err.response.status === 400){
            let validation_errors = err.response.data

            for (let key in validation_errors){
              for (let index in validation_errors[key]){
                this.setNotification({
                  message: validation_errors[key][index],
                  color: 'error'
                })
                break;
              }
              break
            }
          }
        })

      }else{
        this.setNotification({
          message: "Please fix errors",
          color: 'error'
        })
      }


    },
    logout() {
      if (this.socket !== null) {
        this.socket.close(3001, "User logged out")
      }
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      this.$http.post('/rest-auth/logout/', {}).then(response => {
        window.location.replace('/login')
      })

    }
  }
}
</script>

<style scoped>
.v-list.pending{
  height: 300px;
  overflow-y:auto
}
.user-btn {
  background: transparent !important;
  color: white !important;
}
.my-menu {
  margin-top: 40px;
  contain: initial;
  overflow: visible;
}
.my-menu::before {
  position: fixed;
  content: "";
  top: 12.2%;
  right: 10.21%;
  transform: translateY(-100%);
  width: 10px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #fff;
  display: none;
}
.my-menu-hide {
  display: none;
}
</style>

const state = {
    notifications: []
};

const getters = {
    notifications: (state) => {
        return state.notifications
    },
};

const actions = {
    async setNotification({ commit }, notification) {
        commit("newNotification", notification);
        setTimeout(() => commit("deleteNotification"), 3000);

    },
};

const mutations = {
    newNotification: (state, notification) =>
        (state.notifications.push(notification)),
    deleteNotification: (state) =>
        (state.notifications.splice(0, 1)),
};

export default {
    state,
    getters,
    actions,
    mutations,
};

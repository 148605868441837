const state = {
    allSessions: [],
    isLoading: true
};

const getters = {
    allSessions: (state) => state.allSessions,
    isLoading: (state) => state.isLoading,
};

const actions = {};

const mutations = {
    setAllSessions (state, session_ids) {
        state.allSessions = session_ids
    },
    deleteSession(state, sessionId) {
        const index = state.allSessions.indexOf(sessionId)
        state.allSessions.splice(index, 1)
    },
    setLoading(state, isLoading) {
       state.isLoading = isLoading
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
};

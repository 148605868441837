<template>
  <v-container fluid>
    <v-overlay
        z-index="100"
        :value="overlay"
    >
      <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
      />
    </v-overlay>
    <v-row>
      <v-col
          cols="12"
          sm="4">
        <h1 class="mb-1">Tech Analytics</h1>
      </v-col>
      <v-col
          class="d-flex"
          cols="12"
          sm="4"
      >
        <v-select
            :items="services"
            label="Select Service"
            dense
            solo
            v-model="service"
            @change="getResults"
        ></v-select>
      </v-col>
    </v-row>
    <!-- <v-container fluid> -->
      <v-row align="center">
      <v-col md="4" class="my-4">
          <v-card
            class="mx-auto"
          >
            <v-card-title>Total Verifications</v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text><h1>
                {{ verificationResults.verified + verificationResults.not_verified + verificationResults.incomplete }}
              </h1></v-card-text>
          </v-card>
        </v-col>
        <v-col md="2">
          <v-card
            class="mx-auto"
          >

            <v-card-title>Verified</v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text><h1>{{ verificationResults.verified }}</h1></v-card-text>
          </v-card>
        </v-col>
        <v-col md="2">
          <v-card
            class="mx-auto"
          >

            <v-card-title>Not Verified</v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text><h1>{{ verificationResults.not_verified }}</h1></v-card-text>
          </v-card>
        </v-col>
        <v-col md="2">
          <v-card
            class="mx-auto"
          >
            <v-card-title>Incomplete</v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text><h1>{{ verificationResults.incomplete }}</h1></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    <v-card  class="mb-4">
      <v-container fluid>
      <v-row align="center">
        <v-col md="6">
            <v-col>

              <v-card-title>Client Usage</v-card-title>

              <v-divider class="mx-4 mb-4"></v-divider>
              <apexchart ref="clientUsageChart" height="250" width="550" type="donut"
                         :options="cardTypeChartOptions" :series="cardTypeSeries"></apexchart>
            </v-col>
        </v-col>
        <v-col md="6">
            <v-col>

              <v-card-title>Monthly Usage</v-card-title>

              <v-divider class="mx-4 mb-4"></v-divider>

              <apexchart ref="monthUsageChart" height="250" width="550" type="donut"
                         :options="monthUsageChartOptions" :series="monthSeries"></apexchart>
            </v-col>
        </v-col>
      </v-row>
  </v-container>

    </v-card>
    <v-card>
      <v-container fluid>
        <v-row>
          <v-col
              class="d-flex"
              cols="12"
              sm="6">
            <v-card-title>Total Verifications</v-card-title>

          </v-col>
          <v-col
              class="d-flex"
              cols="12"
              sm="3"
          >
            <v-select
                :items="months"
                label="Select Month"
                dense
                solo
                v-model="month"
                @change="getData(month, year, false, true)"
            ></v-select>
          </v-col>
          <v-col
              class="d-flex"
              cols="12"
              sm="3"
          >
            <v-select
                :items="years"
                label="Select Year"
                dense
                solo
                v-model="year"
                @change="getData(month, year, false, true)"
            ></v-select>
          </v-col>
        </v-row>
            <v-divider class="mx-4 mb-4"></v-divider>

        <v-row>
          <v-col>
            <apexchart ref="verificationChart" height="450"
                       :options="productOptions" :series="productSeries" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import Vue from "vue";
import {mapActions} from "vuex";

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
export default {
  name: "TechPartnerAnalytics",
  data() {
    return {
      month: null,
      year: null,
      avgMonth: null,
      avgYear: null,
      allRecMonth: null,
      allRecYear: null,
      overlay: true,
      service: null,
      verificationResults: {
        verified: 0,
        not_verified: 0,
        incomplete: 0,
        pending: 0,
        card_types: {},
        client_results: {},
        auto: 0,
        manual: 0
      },
      serviceNameMap: {
        "fingerprint_capture": "Fingerprints",
        "face_liveness": "Face Liveness"
      },
      services: [],
      months: [
        { value: 1, text: "January", },
        { value: 2, text: "February", },
        { value: 3, text: "March", },
        { value: 4, text: "April", },
        { value: 5, text: "May", },
        { value: 6, text: "June", },
        { value: 7, text: "July", },
        { value: 8, text: "August", },
        { value: 9, text: "September", },
        { value: 10, text: "October", },
        { value: 11, text: "November", },
        { value: 12, text: "December", },
      ],
      years: [
        { value: 2020, text: "2020", },
        { value: 2021, text: "2021", },
        { value: 2022, text: "2022", },
        { value: 2023, text: "2023", },
        { value: 2024, text: "2024", },
        { value: 2025, text: "2025", },
      ],
      options: {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          type: 'datetime',
          categories: [],
          labels: {
            format: 'MMM dd',
            showDuplicates: false,
            style: {
              fontSize: '14px',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '14px',
            }
          }
        },
        legend: {
          position: 'left',
          offsetY: 40,
          fontSize: '16px'
        },
        noData: {
          text: 'Loading...'
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              borderColor: '#e30000',
              label: {
                borderColor: '#e30000',
                style: {
                  color: '#fff',
                  background: '#e30000'
                },
                text: 'Average'
              }
            }
          ]
        }
      },
      allRecOptions: {
        xaxis: {
          type: 'datetime',
          categories: []
        },
        legend: {
          position: 'left',
          offsetY: 40,
        },
        noData: {
          text: 'Loading...'
        },
        stroke: {
          width: 3
        }
      },
      cardTypeSeries: [],
      monthSeries: [],
      cardTypeChartOptions: {
        labels: [],
      },
      monthUsageChartOptions: {
        labels: [],
      },
      series: [
        {
          name: 'Average',
          data: []
        },
        {
          name: 'Minimum',
          data: []
        },
        {
          name: 'Maximum',
          data: []
        },
      ],
      allRecSeries: [
        {
          name: 'All Series',
          data: []
        }
      ],
      productSeries: [],
      productOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10
          },
        },
        xaxis: {
          type: 'datetime',
          categories: [],
          labels: {
            format: 'MMM dd',
            showDuplicates: false,
            style: {
              fontSize: '14px',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '14px',
            }
          }
        },
        legend: {
          position: 'top',
          offsetX: 0,
          fontSize: '16px',
        },
        noData: {
          text: 'Loading...'
        },
        fill: {
          opacity: 1
        },
      }
    }
  },
  mounted() {
    const today = new Date()
    const currentYear = today.getFullYear()
    const currentMonth = today.getMonth()+1
    this.year = currentYear
    this.month = currentMonth
    this.avgYear = currentYear
    this.avgMonth = currentMonth
    this.allRecYear = currentYear
    this.allRecMonth = currentMonth
    this.getTechPartner()
    this.getResults()
  },
  methods: {
    ...mapActions(["setNotification"]),
    getResults() {
      this.getVerificationResults()
      this.getData(this.month, this.year, true, true)
    },
    calculateAverage(arr) {
      const sum = arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      return (sum / arr.length) || 0;
    },
    changeDateFormat(date) {
      const splitDate = date.slice(0, 10)
      return new Date(splitDate).getTime()
    },
    updateVerificationChart(dates, seriesData) {
      // console.log(seriesData)
      seriesData.forEach(e => {
        e.name += ` (${e.data.reduce((a, b) => a + b, 0).toString()})`
      })
      let convertedDates = []
      dates.forEach(d => {
        convertedDates.push(this.changeDateFormat(d))
      })
      if (this.$refs.verificationChart !== undefined && this.$refs.verificationChart !== null) {
        this.$refs.verificationChart.updateOptions({ legend: { showForSingleSeries: true } })
        this.$refs.verificationChart.updateOptions({
          xaxis: {
            categories: convertedDates
          }
        })
        this.$refs.verificationChart.updateSeries(seriesData, false, true);
        if (dates.length === 0) {
          this.$refs.verificationChart.updateOptions({ legend: { showForSingleSeries: false } })
          this.$refs.verificationChart.updateOptions({
            noData: {
              text: 'No Data!!!'
            }
          })
        }
      }
    },
    updateAverageTimeChart(dates, averageTime) {
      if (this.$refs.averageChart !== undefined && this.$refs.averageChart !== null) {
        this.$refs.averageChart.updateOptions({
          xaxis: {
            categories: dates
          },
          annotations: {
            yaxis: [
              {
                y: this.calculateAverage(averageTime[0]).toFixed(2),
                borderColor: '#e30000',
                label: {
                  borderColor: '#e30000',
                  style: {
                    color: '#fff',
                    background: '#e30000'
                  },
                  text: 'Average'
                }
              }
            ]
          }
        })
        this.$refs.averageChart.updateSeries([
          {
            name: 'Average',
            data: averageTime[0]
          },
          {
            name: 'Minimum',
            data: averageTime[1]
          },
          {
            name: 'Maximum',
            data: averageTime[2]
          },
        ], false, true);
        if (dates.length === 0) {
          this.$refs.averageChart.updateOptions({
            noData: {
              text: 'No Data!!!'
            }
          })
        }
      }
    },
    getTechPartner() {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user && user.technology_partner && user.technology_partner.services) {
        user.technology_partner.services.forEach(s => {
          this.services.push({ value: s, text: this.serviceNameMap[s] })
        })
        this.service = user.technology_partner.services[0]
      }
    },
    getData(month, year, updateAverage=false, updateVerification=false) {
      if (updateAverage && this.$refs.averageChart !== undefined && this.$refs.averageChart !== null) {
        this.$refs.averageChart.updateSeries([
          {
            name: 'Average',
            data: []
          },
          {
            name: 'Minimum',
            data: []
          },
          {
            name: 'Maximum',
            data: []
          },
        ], false, true);
        this.$refs.averageChart.updateOptions({
          noData: {
            text: 'Loading!!!'
          }
        })
      }
      if (updateVerification && this.$refs.verificationChart !== null && this.$refs.verificationChart !== undefined) {
        this.$refs.verificationChart.updateSeries([], false, true);
        this.$refs.verificationChart.updateOptions({ legend: { showForSingleSeries: false } })
        this.$refs.verificationChart.updateOptions({
          noData: {
            text: 'Loading!!!'
          }
        })
      }
      this.getVerificationResults()
    },
    getVerificationResults() {
      let data = {}
      data["service"] = this.service
      data["verification_filter_date"] = `${this.month}-${this.year}`
      this.$http.post(`/verification-results`, data).then(res=> {
        let cardTypes = []
        let cardNums = []
        this.verificationResults = res.data
        // console.log(res.data)
        if (this.verificationResults && Object.keys(this.verificationResults.client_results).length > 0) {
          Object.keys(this.verificationResults.client_results).forEach(type => {
            cardTypes.push(type)
            cardNums.push(this.verificationResults.client_results[type])
            if (this.$refs.clientUsageChart !== null && this.$refs.clientUsageChart !== undefined) {
              this.$refs.clientUsageChart.updateOptions({ labels: cardTypes })
              this.$refs.clientUsageChart.updateSeries(cardNums, false, true);
            }
          })
          this.updateVerificationResults(res.data.month_usage)
          this.updateVerificationChart(res.data.client_count.dates, res.data.client_count.count)
        } else {
          // console.log("No data!!!!!!!!!!")
          if (this.$refs.clientUsageChart !== null && this.$refs.clientUsageChart !== undefined) {
            this.$refs.clientUsageChart.updateOptions({
              noData: {
                text: 'No Data!!!'
              }
            })
          }
          if (this.$refs.monthUsageChart !== null && this.$refs.monthUsageChart !== undefined) {
            this.$refs.monthUsageChart.updateOptions({
              noData: {
                text: 'No Data!!!'
              }
            })
          }
          this.overlay = false
        }
      }).catch((error) => {
        console.log(error.stack)
        this.setNotification({
          message: error,
          color: 'error'
        })
      })
    },
    updateVerificationResults(results) {
      let months = []
      let monthCount = []
      Object.keys(results).forEach(month => {
        months.push(month)
        monthCount.push(results[month])
        if (this.$refs.monthUsageChart !== null && this.$refs.monthUsageChart !== undefined) {
          this.$refs.monthUsageChart.updateOptions({ labels: months })
          this.$refs.monthUsageChart.updateSeries(monthCount, false, true);
        }
      })
      this.overlay = false
    }
  },
}
</script>

<style scoped>

</style>
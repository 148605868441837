import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Main from '../views/Main.vue'
import Login from '../views/Login.vue'
import ResetPw from '../views/ResetPw.vue'
import NewPw from '../views/NewPw.vue'
import Analytics from "../views/Analytics";
import TechPartnerAnalytics from "../views/TechPartnerAnalytics";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    meta: {
      requireAuth: true
    },
    children:[
      {
        path:'/home',
        name:'Home',
        component:Home
      },
      {
        path:'/tech-analytics',
        name:'TechAnalytics',
        component: () => import(/* webpackChunkName: "about" */ '../views/TechPartnerAnalytics.vue')
      },
      {
        path:'/analytics',
        name:'Analytics',
        component: () => import(/* webpackChunkName: "about" */ '../views/Analytics.vue')
      },
      {
        path: '/checks',
        name: 'Checks',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Checks.vue')
      },
      {
        path: '/pending-checks',
        name: 'PendingChecks',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PendingChecks.vue')
      },
      {
        path: '/agents',
        name: 'Agents',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Agents.vue')
      },
      {
        path: '/verification-officers',
        name: 'VerificationOfficer',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/VerificationOfficer.vue')
      },
      {
        path: '/clients',
        name: 'Clients',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Clients.vue')
      },
      {
        path: '/update-profile',
        name: 'UpdateProfile',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/UpdateProfile.vue')
      },
      {
        path: '/checks/:id',
        name: 'CheckDetail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/CheckDetail.vue')
      }
    ],
  },
  {
    path:'/resetpw',
    name:'Reset Password',
    component: ResetPw,
    meta: {
      requireAuth: false
    }
  },
  {
    path:'/reset/:uid/:token',
    name:'Reset Password',
    component: NewPw,
    meta: {
      requireAuth: false
    }
  },
  {
    path:'/login',
    name:'Login',
    component: Login,
    meta: {
      requireAuth: false
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let token = window.localStorage.getItem('token')
  let user = JSON.parse(window.localStorage.getItem('user'))
  if (user && user.technology_partner) {
    if (to.path !== '/tech-analytics') next({name: 'TechAnalytics'})
    else next()
  }
  else {
    if (to.meta.requireAuth !== false && !token) next({ name: 'Login' })
    else if (to.path === '/') next({name: 'Checks'})
    else next()
  }
})

export default router

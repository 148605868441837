<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Enter your new password</v-toolbar-title>
          </v-toolbar>
          <v-form @submit.prevent="resetPw">
            <v-card-text>
              <span>Enter your new password</span>

              <v-text-field
                prepend-icon="lock"
                name="newPw"
                v-model="newPw"
                label="New Password"
                type="password"
              ></v-text-field>

              <v-text-field
                prepend-icon="lock"
                name="newPwRepeat"
                label="Repeat New Password"
                type="password"
                v-model="newPwRepeat"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn type="submit">Set New Password</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackbar.display"
      :color="snackbar.color"
      timeout="2000"
      right
      top
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      newPw: "",
      newPwRepeat: "",
      snackbar: {
        color: "",
        display: false,
        text: "",
      }
    };
  },
  methods: {
    validateDetails() {
      if (this.newPw.trim() === "") {
        this.snackbar = {
          color: "error",
          display: true,
          text: "Kindly add password first",
        };
        return false;
      }
      if (this.newPwRepeat.trim() === "") {
        this.snackbar = {
          color: "error",
          display: true,
          text: "Kindly rewrite password",
        };
        return false;
      }
      if (this.newPwRepeat !== this.newPw) {
        this.snackbar = {
          color: "error",
          display: true,
          text: "Passwords dont match",
        };
        return false;
      }
      return true;
    },
    async resetPw() {
      if (this.validateDetails()) {
        res = this.$http
          .post("/rest-auth/password/reset/confirm/", {
            uid:this.$route.params.uid,
            token:this.$route.params.token,
            new_password1: this.newPw,
            new_password2: this.newPwRepeat
          })
          .then((response) => {
            if (response.status === 200) {
              this.snackbar = {
                color: "success",
                display: true,
                text: "Password has been reset",
              };
              window.location.replace('/login')
            } else {
              this.snackbar = {
                color: "error",
                display: true,
                text: JSON.stringify(response),
              };
            }
          })
          .catch((err) => {
            console.log(JSON.stringify(err))
            this.snackbar = {
              color: "error",
              display: true,
              text: "error",
            };
          });
      }
    },
  },
};
</script>